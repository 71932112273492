import { Controller } from '@hotwired/stimulus'

let newIndex
let index
export default class extends Controller {
  static targets = ['document', 'upload', 'clearButton', 'hubMessageFiles', 'documentMessage', 'showFiles', 'subText']

  static values = {
    type: String,
    images: Array,
    index: Number
  }

  connect () {
  }

  showDocument () {
    this.documentMessageTarget.classList.add('w-full')
    this.element.parentElement.previousSibling.classList.add('hidden')
    this.element.parentElement.querySelector('#audio-video-message').classList.add('hidden')
    this.documentTarget.classList.toggle('hidden')
    this.uploadTarget.classList.toggle('hidden')
    this.clearButtonTarget.classList.remove('hidden')
    this.hubMessageFilesTarget.click()
  }

  changeImage (e) {
    e.preventDefault()

    index = index === undefined ? this.indexValue : newIndex

    newIndex = this.typeValue === 'left' ? index - 1 : index + 1
    newIndex = this.updateImage(newIndex, this.element, this.typeValue)

    this.element.closest('.modal').querySelector('#preview').src = this.imagesValue[newIndex]
    index = newIndex
  }

  updateImage (newIndex, element, type) {
    if (newIndex === 0) {
      element.classList.add('hidden')
      if (this.typeValue === 'left') {
        element.nextSibling.classList.remove('hidden')
      }
    } else if (newIndex > 0 && newIndex < this.imagesValue.length - 1) {
      element.classList.remove('hidden')
      const sibling = type === 'left' ? element.nextSibling : element.previousElementSibling
      sibling.classList.remove('hidden')
    } else if (newIndex === this.imagesValue.length - 1) {
      element.classList.add('hidden')
    } else {
      index = this.indexValue
      newIndex = index
      element.classList.add('hidden')
    }
    return newIndex
  }

  showfiles (e) {
    const files = e.target.files
    if (files.length > 0) {
      e.target.parentElement.parentElement.classList.add('hidden')
      this.iterateFiles(e, files)
    } else {
      e.target.parentElement.parentElement.classList.remove('hidden')
    }
    this.subTextTarget.classList.remove('hidden')
    this.element.parentElement.lastChild.classList.add('hidden')
  }

  iterateFiles (e, files) {
    for (const file in files) {
      if (+file || +file === 0) {
        const imageSpan = document.createElement('span')
        imageSpan.classList.add('fa', 'fa-image', 'fa-3x')
        imageSpan.title = files[file].name
        e.target.parentElement.parentElement.nextSibling.appendChild(imageSpan)
      }
    }
  }

  backToForm () {
    this.element.parentElement.previousSibling.classList.remove('hidden')
    this.documentMessageTarget.classList.remove('w-full')
    this.uploadTarget.classList.remove('hidden')
    this.documentTarget.classList.add('hidden')
    this.element.nextElementSibling.querySelector('#audio-video-message').classList.remove('hidden')
    if (this.showFilesTarget.hasChildNodes()) {
      this.showFilesTarget.innerHTML = ''
    }
    this.subTextTarget.classList.add('hidden')
    this.element.parentElement.lastChild.classList.remove('hidden')
  }
}
