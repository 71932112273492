import videojs from "video.js";
import "videojs-record/dist/videojs.record.js";
import "videojs-record/dist/plugins/videojs.record.ts-ebml.js";
import "recordrtc";
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import { convertVideoUsingTsEBML } from './recordUtils'

export class VideoWrapper {
  constructor({
    videoId,
    startCheckingCallback,
    stopCheckingCallback,
    readyCallback,
    errorCallback,
    finishRecordCallback,
    startRecordCallback
  })
  {
    this._player = videojs(videoId, {
      controls: false,
      loop: false,
      fluid: true,
      plugins: {
        record: {
          videoMimeType: "video/mp4",
          audio: true,
          maxLength: 18000,
          debug: true,
          video: {
            width: 600,
            height: 380
          }
        }
      }
    });

    startCheckingCallback();

    this._player.one("deviceReady", () => {
      this._player.record().enumerateDevices();
    });

    // this._player.on("enumerateReady", () => {
    //   this._devices = Array.from(new Set(this._player.record().devices));
    //   if (enumerateReadyCallback) {
    //     enumerateReadyCallback();
    //   }
    // });

    this._player.on("deviceReady", () => {
      readyCallback();
    });

    this._player.on("deviceError", () => {
      setTimeout(() => {
        stopCheckingCallback();
      }, 1000);

      if (this._player.deviceErrorCode.name === "NotAllowedError") {
        errorCallback({
          name: "Access to camera and microphone denied",
          message: "Please enable camera and microphone access on your browser."
        });
      } else {
        errorCallback({
          name: "Camera and microphone not found",
          message:
            "Please ensure camera and microphone are turned on and not used by other applications (Skype, Google Hangouts, etc.)."
        });
      }
    });

    this._player.on("error", (element, error) => {
      errorCallback({
        name: "device or browser error",
        message: error
      })
    });

    this._player.on("startRecord", () => {
      if (startRecordCallback) {
        startRecordCallback("video");
      }
    });

    this._player.on("finishRecord", () => {
      const video = this._player.record();
      video.stopDevice();
      videojs.convertVideoUsingTsEBML = convertVideoUsingTsEBML;
      videojs.convertVideoUsingTsEBML(
        this._player.recordedData,
        convertedData => {
          if (finishRecordCallback) {
            finishRecordCallback(convertedData);
          }
          if (this.stopRecordingCallback) {
            this.stopRecordingCallback(convertedData);
          }
        }
      );
    });

    this._player.record().getDevice();
  }

  startRecording() {
    return new Promise(resolve => {
      this._player.record().start();
      resolve();
    });
  }

  stopRecording() {
    return new Promise(resolve => {
      this.stopRecordingCallback = resolve;
      this._player.record().stop();
    });
  }

  player() {
    return this._player;
  }

  devices() {
    return this._devices;
  }

  //
  // In case we want to allow user to choose Video device from available devices
  // setVideoInput(deviceId, afterSetCallback) {
  //   try {
  //     this._player.record().setVideoInput(deviceId);
  //     if (afterSetCallback) {
  //       afterSetCallback();
  //     }
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // }
  //
  // In case we want to allow user to choose Audio device from available devices
  //
  // setAudioInput(deviceId, afterSetCallback) {
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       audio: {
  //         deviceId: {
  //           exact: deviceId
  //         }
  //       },
  //       video: true
  //     })
  //     .then(stream => {
  //       this._audio_stream = stream;
  //       const audioContext = new AudioContext();
  //       const analyser = audioContext.createAnalyser();
  //       const microphone = audioContext.createMediaStreamSource(stream);
  //       const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
  //       analyser.smoothingTimeConstant = 0.8;
  //       analyser.fftSize = 1024;
  //       microphone.connect(analyser);
  //       analyser.connect(javascriptNode);
  //       javascriptNode.connect(audioContext.destination);

  //       this._player.record().setAudioInput(deviceId);

  //       javascriptNode.onaudioprocess = () => {
  //         const frequencyBinCounts = new Uint8Array(analyser.frequencyBinCount);
  //         analyser.getByteFrequencyData(frequencyBinCounts);
  //         if (afterSetCallback) {
  //           afterSetCallback(
  //             Math.round(
  //               mean(frequencyBinCounts) / SETTINGS_VOLUME_INDICATOR_SIZE
  //             )
  //           );
  //         }
  //       };
  //     })
  //     .catch(error => {
  //       console.warn(error);
  //     });
  // }
  //

  getDeviceInfo(callback) {
    if (callback) {
      callback();
    }
    this._player.record().getDevice();
  }

  destroy() {
    if (this._audio_stream) {
      this._audio_stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    this._player.dispose();
  }
}
