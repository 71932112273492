import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['collapseIcon']

  toggleRow (e) {
    e.target.closest('tr').nextSibling.classList.toggle('hidden')
    this.collapseIconTarget.querySelector('.fa-angle-right').classList.toggle('hidden')
    this.collapseIconTarget.querySelector('.fa-angle-down').classList.toggle('hidden')

    if (e.target.closest('tr').nextSibling.querySelector('.bulk_select')) {
      e.target.closest('tr').nextSibling.querySelector('.bulk_select').style.display = 'none'
    }
  }
}
