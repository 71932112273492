import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import { AudioWrapper } from '../lib/audio_wrapper'
import { VideoWrapper } from '../lib/video_wrapper'

let finalVideoStream
let startInterval
let totalSeconds

function startTimer (id) {
  totalSeconds++
  const seconds = pad(totalSeconds % 60)
  const minutes = pad(parseInt(totalSeconds / 60))

  document.querySelector('#' + id).innerHTML = minutes + ':' + seconds
}

function pad (val) {
  return val > 9 ? val : '0' + val
}

export default class extends Controller {
  static targets = [
    'startAudio', 'stopAudio', 'destroyAudio', 'canvas', 'sendMessage', 'clearButton', 'videoRecorder', 'audioRecorder', 'videoContainer',
    'videoPlayer', 'subText'
  ]

  connect () {
  }

  backToForm () {
    this.element.parentElement.previousSibling.classList.remove('hidden')
    this.element.parentElement.querySelector('#document-message').classList.remove('hidden')
    this.clearButtonTarget.classList.add('hidden')
    this.videoRecorderTarget.classList.add('hidden')
    this.audioRecorderTarget.classList.add('hidden')
    this.element.querySelector('#device_message').classList.add('hidden')
    this.element.classList.remove('w-full')
    this.subTextTarget.classList.add('hidden')
    this.element.nextElementSibling.classList.remove('hidden')
  }

  showVideoRecorder () {
    this.element.parentElement.previousSibling.classList.add('hidden')
    this.element.parentElement.querySelector('#document-message').classList.add('hidden')
    this.videoRecorderTarget.classList.remove('hidden')
    this.clearButtonTarget.classList.remove('hidden')
    this.subTextTarget.classList.remove('hidden')
    this.element.nextElementSibling.classList.add('hidden')

    this.element.classList.add('w-full')

    const video = document.createElement('video')
    video.className = 'video-js vjs-default-skin hidden'
    video.id = 'video_player'
    this.videoContainerTarget.prepend(video)

    this.videoWrapper = new VideoWrapper({
      videoId: 'video_player',
      startCheckingCallback: this.startChecking,
      stopCheckingCallback: this.stopChecking,
      readyCallback: this.videoDeviceReady,
      errorCallback: this.videoDeviceError,
      finishRecordCallback: this.finishedRecording,
      startRecordCallback: this.startedRecording
    })

    if (!this.canvasTarget.classList.contains('hidden')) {
      this.canvasTarget.classList.toggle('hidden')
      this.startAudioTarget.parentNode.classList.toggle('hidden')
    }
  }

  showAudioRecorder () {
    if (this.videoWrapper && this.videoWrapper._player.player_ != null) {
      this.videoWrapper.destroy()
      document.querySelector('#video_start_button').classList.add('hidden')
    }

    this.element.parentElement.previousSibling.classList.add('hidden')
    this.element.parentElement.querySelector('#document-message').classList.add('hidden')
    this.clearButtonTarget.classList.remove('hidden')

    this.audioRecorderTarget.classList.remove('hidden')
    this.videoRecorderTarget.classList.add('hidden')
    this.canvasTarget.classList.remove('hidden')
    this.element.classList.add('w-full')
    this.subTextTarget.classList.remove('hidden')
    this.element.nextElementSibling.classList.add('hidden')

    this.audioWrapper = new AudioWrapper({
      startCheckingCallback: this.startChecking,
      stopCheckingCallback: this.stopChecking,
      readyCallback: this.audioDeviceReady,
      errorCallback: this.videoDeviceError,
      finishRecordCallback: this.finishedAudioRecording,
      startRecordCallback: this.startedRecording
    })
  }

  startChecking () {
    // To show a message on UI device are being checked start video streaming.
    document.querySelector('#device_message').classList.remove('hidden')
    document.querySelector('#device_message').innerHTML = 'Device are being checked.'
  }

  stopChecking () {
    // To show a message that Device check is now Finished.
    document.querySelector('#device_message').innerHTML = 'Device check is now Finished.'
  }

  videoDeviceReady () {
    // To show a message that Device is ready.
    document.querySelector('#device_message').innerHTML = 'Device check is now Finished.'
    document.querySelector('#video_player').classList.remove('hidden')
    document.querySelector('#video_start_button').classList.remove('hidden')
  }

  videoDeviceError (error) {
    // To show error message in the UI, the error message will be received in argument.
    document.querySelector('#device_message').innerHTML = error.name + ': ' + error.message
  }

  finishedRecording (finalVideoStreamData) {
    // This will receive final video stream which then can be sent to backend to store with active storage.
    finalVideoStream = finalVideoStreamData

    document.querySelector('#video_player').classList.toggle('hidden')
    document.querySelector('#VideoPlayer').classList.toggle('hidden')

    const vsrc = window.URL.createObjectURL(finalVideoStreamData)
    const video = document.querySelector('#VideoPlayer')
    video.src = vsrc
  }

  startedRecording (type) {
    // Just to show the timer in HH:MM:SS format, To show this when the
    startInterval = null
    document.querySelector('#' + type + '_recording_time').classList.remove('hidden')
    totalSeconds = 0
    startInterval = setInterval(startTimer, 1000, type + '_recording_time')
  }

  startRecording () {
    // This will be called by user when user click on start recording button.
    this.videoWrapper.startRecording()
    document.querySelector('#video_stop_button').classList.toggle('hidden')
    document.querySelector('#video_start_button').classList.toggle('hidden')
  }

  stopRecording () {
    // This will be called by user when user click on stop recording button.
    this.videoWrapper.stopRecording()
    clearInterval(startInterval)
    document.querySelector('#video_stop_button').classList.toggle('hidden')
    document.querySelector('#send_destroy_video_button').classList.toggle('hidden')
  }

  sendMessage (e) {
    // This will be called by user when user click on send button.
    const path = this.sendMessageTarget.getAttribute('data-path')
    const type = this.sendMessageTarget.getAttribute('data-type')

    const filename = type === 'Audio' ? 'Audio' : 'Video'

    const data = new FormData()
    data.append('hub_message[file]', finalVideoStream, filename)
    data.append('hub_message[_type]', 'Hub::Message::AudioVideo')
    data.append('hub_message[payload][codec]', finalVideoStream.type.split('codecs=')[1] || '')
    post(`${path}`, { body: data, responseKind: 'turbo-stream' })

    if (this.videoWrapper && this.videoWrapper._player.player_ != null) {
      this.videoWrapper.destroy()
      this.element.querySelector('#video_start_button').classList.add('hidden')
    }
  }

  destroyVideo () {
    this.videoWrapper.destroy()
    this.hideShowElement('video', this.element)
    this.videoPlayerTarget.classList.toggle('hidden')

    const video = document.createElement('video')
    video.className = 'video-js vjs-default-skin hidden'
    video.id = 'video_player'
    this.videoContainerTarget.prepend(video)
  }

  startAudioRecording (e) {
    this.audioWrapper.startRecording()
    this.startAudioTarget.parentNode.classList.toggle('hidden')
    this.stopAudioTarget.parentNode.classList.toggle('hidden')
  }

  stopAudioRecording (e) {
    this.audioWrapper.stopRecording()
    clearInterval(startInterval)
    this.stopAudioTarget.parentNode.classList.toggle('hidden')
    this.canvasTarget.classList.toggle('hidden')
    this.element.querySelector('#send_destroy_audio_button').classList.toggle('hidden')
  }

  audioDeviceReady () {
    // To show a message that Device is ready.
    document.querySelector('#device_message').innerHTML = 'Device check is now Finished.'
    document.querySelector('#audio_start_button').classList.remove('hidden')
  }

  finishedAudioRecording (chunks) {
    const audio = document.createElement('audio')
    audio.setAttribute('controls', '')
    audio.setAttribute('style', 'width: 100%')
    audio.setAttribute('data-video-recorder-target', 'destroyAudio')
    document.querySelector('#audio-container').prepend(audio)

    audio.controls = true
    const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
    const audioURL = window.URL.createObjectURL(blob)
    audio.src = audioURL
    finalVideoStream = blob
  }

  destroyAudio (e) {
    this.destroyAudioTarget.remove()

    this.hideShowElement('audio', this.element)
  }

  hideShowElement (id, element) {
    element.querySelector('#send_destroy_' + id + '_button').classList.toggle('hidden')
    element.querySelector('#device_message').classList.toggle('hidden')
    element.querySelector('#' + id + '_recording_time').classList.toggle('hidden')
    element.querySelector('#' + id + '_recording_time').innerHTML = ''
    element.classList.remove('w-full')
  }
}
