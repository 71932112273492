import { Controller } from '@hotwired/stimulus'
import { EventBus } from 'lib/events'

export default class extends Controller {
  static targets = ['content', 'button']

  connect (e) {
    this.setShowMore()

    EventBus.on('modal:show', (modalEl) => {
      this.setShowMore()
    })
  }

  toggleContent () {
    this.contentTarget.classList.toggle('max-h-60')
    if (this.buttonTarget.innerHTML === 'Show More...') {
      this.buttonTarget.innerHTML = 'Less'
    } else {
      this.buttonTarget.innerHTML = 'Show More...'
    }
  }

  setShowMore () {
    if (this.contentTarget.clientHeight > 260) {
      this.contentTarget.classList.add('max-h-60')
      this.buttonTarget.classList.remove('hidden')
    }
  }
}
