import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  input () {
    let value = ''
    value = this.element.value.replace(/[^\d]/g, '')

    const area = value.substr(0, 3)
    const zone = value.substr(3, 3)
    const last = value.substr(6, 4)

    console.log('value', value, value.length, area, zone, last)

    let display = ''
    if (area.length > 0) {
      display = '(' + area
    }
    if (zone !== '') {
      display += ') ' + zone
    }
    if (last !== '') {
      display += '-' + last
    }

    this.element.value = display
  }
}
