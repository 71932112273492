import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  fill (e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    this.inputTarget.value = e.target.dataset.fill
  }
}
