import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  input () {
    let value = ''
    value = this.element.value.replace(/[^\d/]/g, '')

    const lastChar = value[value.length - 1]
    // console.log(`Short date format ${value}, ${value.length} length, ${lastChar}`)

    if (value.length !== 3 && value.length !== 6 && lastChar === '/') {
      value = value.substr(0, value.length - 1)
    }

    if (value.length >= 3 && value[2] !== '/') { value = value.substr(0, 2) + '/' + value.substr(2) }

    if (value.length >= 6 && value[5] !== '/') { value = value.substr(0, 5) + '/' + value.substr(5) }

    if (value.length > 10) { value = value.substr(0, 10) }

    this.element.value = value
  }
}
