import { Controller } from '@hotwired/stimulus'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css' // Re-uses images from ~leaflet package
import * as L from 'leaflet'
import 'leaflet-defaulticon-compatibility'
import { forIn } from 'lodash'
import { EventBus } from 'lib/events'

export default class extends Controller {
  static targets = ['map']
  static values = {
    centerLocation: Array,
    markers: Object,
    zoom: Number,
    inModal: Boolean
  }

  connect (e) {
    if (this.inModalValue) {
      EventBus.on('modal:show', (modalEl) => {
        if (modalEl.contains(this.element)) {
          this.initMap()
        }
      })
    } else {
      this.initMap()
    }
  }

  disconnect () {
    EventBus.removeAllListeners('modal:show')
  }

  initMap () {
    if (this.map) { return }

    const zoom = this.zoomValue

    this.map = L.map(this.element, {
      center: this.centerLocationValue,
      zoom: zoom,
      scrollWheelZoom: false
    })

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map)

    forIn(this.markersValue, (providers, location) => {
      if (providers.length > 0) {
        L.marker(location.split(',')).addTo(this.map)
          .bindPopup(providers.join(', '))
      } else {
        L.marker(location.split(',')).addTo(this.map)
      }
    })
  }
}
