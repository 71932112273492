import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['newTemplate', 'container']

  initialize () {
    this.newTemplate = this.newTemplateTarget.cloneNode(true)
    this.newTemplateTarget.parentNode.removeChild(this.newTemplateTarget)
  }

  add (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    this.containerTarget.appendChild(this.newTemplate.cloneNode(true))
  }

  remove (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    const el = e.currentTarget.closest('.multi-input')
    el.parentNode.removeChild(el)
  }

  // Might not need anymore
  // closest(el, selector) {
  //   var retval = null;
  //   while (el) {
  //     if (el.matches(selector)) {
  //       retval = el;
  //       break
  //     }
  //     el = el.parentElement;
  //   }
  //   return retval;
  // }
}
