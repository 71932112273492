import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    common: Boolean
  }

  static targets = ['content', 'tabs']

  connect () {
    const tabTogglers = this.tabsTarget.children
    tabTogglers[0].click()
  }

  fetchContent (e) {
    const params = new URLSearchParams()
    const path = e.target.getAttribute('data-path')
    params.append('id', e.target.getAttribute('data-frame-id'))
    get(`${path}?${params}`, { responseKind: 'turbo-stream' })
  }

  activeTab (e) {
    e.preventDefault()

    const target = this.tabName(e.target)
    if (!this.commonValue) {
      const id = target.getAttribute('data-content-id')
      // Get current Tab element that is clicked
      const lastActiveTabContent = this.contentTarget.querySelector('.tab-content:not(.hidden)')
      if (lastActiveTabContent) { lastActiveTabContent.classList.add('hidden') }

      this.contentTarget.querySelector('#' + id).classList.remove('hidden')
    }

    const activeTab = this.tabsTarget.querySelector('.border-b-4')
    if (activeTab) { this.updateTabTogglers([activeTab], 0) }

    // Change classes of active tabs
    e.target.classList.remove('text-gray-500')
    e.target.classList.add('text-black', 'border-primary', 'border-b-4')
    this.toggleTextClass(e.target.firstChild.classList, 'add')
    // if (e.target.firstChild.classList !== undefined) {
    //   e.target.firstChild.classList.add("text-primary");
    // }
  }

  tabName (target) {
    if (target.nodeName === 'LI') {
      return target.firstChild
    } else if (target.nodeName === 'A') {
      return target
    }
  }

  updateTabTogglers (tabTogglers, i) {
    tabTogglers[i].classList.add('text-gray-500')
    tabTogglers[i].classList.remove('border-primary', 'text-black', 'border-b-4', 'text-primary')
    this.toggleTextClass(tabTogglers[i].firstChild.classList, 'remove')
    // if (tabTogglers[i].firstChild.classList !== undefined) {
    //   tabTogglers[i].firstChild.classList.remove("text-primary");
    // }
    tabTogglers[i].parentNode.classList.remove('border-primary', 'border-b-4')
  }

  toggleTextClass (eleClass, operation) {
    if (eleClass !== undefined) {
      if (operation === 'add') { return eleClass.add('text-primary') }

      eleClass.remove('text-primary')
    }
  }
}
