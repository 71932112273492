import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

let page = 1
export default class extends Controller {
  static values = {
    restrict: String
  }

  static targets = ['restricted', 'message', 'date', 'messageDate']

  initialize () {
    this.observeViewed()
    this.readTimeouts = {}
  }

  connect () {
    this.scroll = this.scroll.bind(this)
    this.scrollToLast = this.scrollToLast.bind(this)
    this.markRead = this.markRead.bind(this)
    this.clearRead = this.clearRead.bind(this)

    this.scrollToLast()
    this.removeRestricted()

    const config = { childList: true }
    this.observer = new window.MutationObserver(this.scroll)
    this.observer.observe(this.element, config)
  }

  disconnect () {
    this.observer.disconnect()
    this.intersectionObserver.disconnect()

    for (const key in this.readTimeouts) {
      if (Object.prototype.hasOwnProperty.call(this.readTimeouts, key)) {
        window.clearTimeout(this.readTimeouts[key])
        delete this.readTimeouts[key]
      }
    }
  }

  scroll (mutationsList, _observer) {
    for (const mutation of mutationsList) {
      this.scrollToMessage(mutation)
    }
  }

  scrollToMessage (mutation) {
    if (mutation.type === 'childList') {
      if (mutation.addedNodes.length === 1 && !mutation.addedNodes[0].classList.contains('hidden')) {
        this.scrollToLast()
      }
      this.removeRestricted()
    }
  }

  scrollToLast () {
    const children = this.element.children
    if (children.length > 0) {
      children[children.length - 1].scrollIntoView()
    }
  }

  removeRestricted () {
    this.restrictedTargets.forEach((el) => {
      if (el.dataset.restrictedTo !== this.restrictValue) {
        el.remove()
      }
    })
  }

  messageTargetConnected (el) {
    if (el.dataset.isRead !== 'true') {
      console.log(`watching ${el.id} is unread`)
      this.intersectionObserver.observe(el)
    }
  }

  observeViewed () {
    this.intersectionObserver = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio <= 0) {
          this.clearRead(entry)
        } else {
          this.markRead(entry)
        }
      })
    })
  }

  markRead (entry) {
    const targetId = entry.target.id
    const timeout = window.setTimeout(() => {
      this.intersectionObserver.unobserve(entry.target)
      entry.target.classList.remove('bg-gray-300')
      window.hubChannel.read(targetId)
    }, 3000)

    this.readTimeouts[targetId] = timeout
  }

  clearRead (entry) {
    const targetId = entry.target.id
    const timeout = this.readTimeouts[targetId]
    if (timeout !== null) {
      window.clearTimeout(timeout)
      delete this.readTimeouts[targetId]
    }
  }

  loadMessages (e) {
    if (e.target.scrollTop === 0 && page < parseInt(e.target.getAttribute('data-pages'))) {
      page += 1
      const params = new URLSearchParams()
      const path = e.target.getAttribute('data-path')
      params.append('page', page)

      if (!e.target.querySelector('#no_data')) {
        get(`${path}?${params}`, { responseKind: 'turbo-stream' })
        e.target.scrollTo(0, 10)
      }
    }
  }

  updateDate (e) {
    const topLabel = this.dateTarget
    const dateLabels = this.messageDateTargets
    let currentLabel = null
    dateLabels.forEach((dateLabel) => {
      dateLabel.classList.remove('hidden')
      const offsetTop = dateLabel.offsetTop
      dateLabel.classList.add('hidden')
      if (e.target.scrollTop >= offsetTop) {
        currentLabel = dateLabel
      }
    })
    if (currentLabel) {
      topLabel.value = currentLabel.innerText
    }
  }
}
