import {Decoder, Encoder, tools, Reader} from 'ts-ebml';
import {blobToArrayBuffer, addFileInfo} from 'videojs-record/src/js/utils/file-util';

export function convertVideoUsingTsEBML(data, resultCallback) {
  if (data.type === 'video/mp4') {
    return resultCallback(data);
  }
  const decoder = new Decoder();
  const reader = new Reader();
  reader.logging = false;
  reader.drop_default_duration = false;

  // save timestamp
  const timestamp = new Date();
  timestamp.setTime(data.lastModified);

  // load and convert blob
  blobToArrayBuffer(data).then((buffer) => {
    // decode
    const elms = decoder.decode(buffer);
    elms.forEach((elm) => {
      reader.read(elm);
    });
    reader.stop();

    // generate metadata
    let refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
    let body = buffer.slice(reader.metadataSize);

    // create new blob
    let result = new Blob([refinedMetadataBuf, body], {type: data.type});

    // add existing file info
    addFileInfo(result, timestamp);

    resultCallback(result);
  });
}
