import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['action'];

  load (e) {
    const params = new URLSearchParams()
    const path = this.actionTarget.getAttribute('data-path')
    params.append('id', this.actionTarget.getAttribute('data-id'))

    get(`${path}?${params}`, { responseKind: 'turbo-stream' })
  }
}
