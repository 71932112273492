import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { forEach } from 'lodash'

export default class extends Controller {
  static targets = ['query', 'results', 'selectedResult', 'inputField']
  static values = {
    searchAction: String,
    multiselect: Boolean,
    existing: String
  }

  connect () {
    this.selectedValues = {}
    this.timeout = null

    if (this.existingValue.length > 0) {
      const array = this.existingValue.split('***')
      array.forEach((a) => {
        const keyValue = a.split('||')
        this.selectedValues[keyValue[0]] = keyValue[1]
      })
    }
    this.updateChosenValues()
  }

  search (_e) {
    const params = new URLSearchParams()
    params.append('query', this.queryTarget.value)
    params.append('target', this.resultsTarget.getAttribute('id'))
    get(`${this.searchActionValue}?${params}`, { responseKind: 'turbo-stream' })
  }

  hideList () {
    setTimeout(() => {
      this.resultsTarget.classList.add('hidden')
    }, 200)
  }

  showList () {
    this.resultsTarget.classList.remove('hidden')
  }

  choose (e) {
    // Note: This is the condition which provide us facility to choose just one option or multiple options from the list.
    if (!this.multiselectValue && this.selectedValues.length === 1) {
      this.hideList() // This will close the options once we click on any option.
      return
    }

    this.selectedValues[e.target.dataset.id] = e.target.dataset.text
    e.target.classList.toggle('hidden')
    this.selectedResultTarget.classList.remove('hidden')

    this.updateChosenValues()
    this.hideList()
  }

  remove (e) {
    delete this.selectedValues[e.target.dataset.id]
    this.updateChosenValues()
  }

  updateChosenValues () {
    const values = []
    for (const [key, value] of Object.entries(this.selectedValues)) {
      values.push(key + '||' + value)
    }

    this.inputFieldTarget.value = values.join('***')

    this.selectedResultTarget.innerHTML = this.selectedOptionsDom()
  }

  selectedOptionsDom () {
    let html = ''
    forEach(this.selectedValues, (name, id) => {
      html += `
        <li class="m-1 max-w-tag px-2 py-1/2 border border-tertiary text-tertiary rounded-full float-left inline-flex text-xs">
          <div class="w-11/12 truncate float-left pr-1" title="${name}">${name}</div>
          <a class="cursor-pointer" data-id="${id}" data-action="click->search-multiselect#remove"> x</a>
        </li>
      `
    })
    return html
  }
}
