let mediaRecorder;
export class AudioWrapper {
  constructor({
    startCheckingCallback,
    stopCheckingCallback,
    readyCallback,
    errorCallback,
    finishRecordCallback,
    startRecordCallback
  })
  {
    startCheckingCallback();

    let audioCtx;
    let canvas = document.querySelector("canvas");
    const canvasCtx = canvas.getContext("2d");

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = { audio: true };
      let chunks = [];
    
      let onSuccess = function(stream) {
        navigator.mediaDevices.enumerateDevices().then(function(devices) {
          setTimeout(() => {
            stopCheckingCallback();
          }, 1000);

          const microphones = devices.filter(d => d.kind === 'audioinput');

          if (microphones.length > 0) {
            microphones.forEach(function(device) {
              if (device.label == "") {
                errorCallback({
                  name: "Access to Microphone denied",
                  message: "Please enable microphone access on your browser."
                });
              }
              else {
                readyCallback();

                mediaRecorder = new MediaRecorder(stream);

                if(!audioCtx) {
                  audioCtx = new AudioContext();
                }
                const source = audioCtx.createMediaStreamSource(stream);
                const analyser = audioCtx.createAnalyser();
                analyser.fftSize = 2048;
                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
              
                source.connect(analyser);

                const WIDTH = canvas.width
                const HEIGHT = canvas.height;
                analyser.getByteTimeDomainData(dataArray);

                canvasCtx.fillStyle = 'rgb(200, 200, 200)';
                canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
                canvasCtx.beginPath();

                let sliceWidth = WIDTH * 1.0 / bufferLength;
                let x = 0;
                
                for(let i = 0; i < bufferLength; i++) {
                  let v = dataArray[i] / 128.0;
                  let y = v * HEIGHT/2;
                  if(i === 0) {
                    canvasCtx.moveTo(x, y);
                  } else {
                    canvasCtx.lineTo(x, y);
                  }
                  x += sliceWidth;
                }

                canvasCtx.lineTo(canvas.width, canvas.height/2);
                canvasCtx.stroke();

                mediaRecorder.onstart = function() {
                  startRecordCallback("audio");
                }
            
                mediaRecorder.onstop = function(e) {
                  finishRecordCallback(chunks);
                  chunks = [];
                }

                mediaRecorder.ondataavailable = function(e) {
                  chunks.push(e.data);
                }
              }
            });
          }
        })
        .catch(function(err) {
          errorCallback({
            name: err.name,
            message: err.message
          });
        });
      }

      let onError = function(err) {
        errorCallback({
          name: "The following error occured: ",
          message: err
        });
      }
      
      navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);
    } else {
      errorCallback({
        name: "The following error occured: ",
        message: "getUserMedia not supported on your browser!"
      });
    }
  }

  startRecording() {
    mediaRecorder.start();
  }

  stopRecording() {
    mediaRecorder.stop();
  }
}
