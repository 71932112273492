import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

/*
 * <button class="btn btn-primary"
 *  data-controller="tooltip"
 *  data-tippy-content="I'm a Tippy tooltip!"
 *  data-tippy-delay="0"
 *  data-tippy-arrow="true"
 *  data-tippy-size="regular"
 *  data-tippy-animation="fade">Hover over me</button>
 */
export default class extends Controller {
  connect () {
    this.tippy = tippy(this.element, { allowHTML: true })
  }

  disconnect () {
    this.tippy.destroy()
  }
}
