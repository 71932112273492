import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'label', 'wrapper']

  connect (e) {
  }

  focus (e) {
  }

  focusOnInput () {
  }

  blur (e) {
  }

  addValidationMessage () {
  }

  input (e) {
    if (e.target.value.length > 0) {
      this.checkValidity({ insertError: false }, e.target)
    } else {
      this.checkValidity({ insertError: true }, e.target)
    }
  }

  invalid (e) {
    e.preventDefault()
    this.checkValidity({ insertError: true }, e.target)
  }

  checkValidity (options, input) {
    const validationErrorClass = 'validation-error'
    const insertError = options.insertError
    const parent = input.parentNode
    const error = parent.getElementsByClassName(`${validationErrorClass}`)[0] || document.createElement('div')

    if (!input.validity.valid && input.validationMessage) {
      error.className = validationErrorClass
      error.textContent = input.validationMessage

      if (insertError) {
        this.insertInputError(input, parent, error)
      }
    } else {
      this.removeInputError(input, parent, error)
    }
  }

  insertInputError (input, parent, error) {
    if (input.getAttribute('type') === 'radio') {
      if (parent.parentNode.parentNode.getElementsByClassName('validation-error').length === 0) {
        parent.parentElement.parentElement.append(error)
      }
    } else {
      parent.appendChild(error)
      parent.classList.add('has-validation-error', 'mb-8')
      input.classList.add('error')
    }
  }

  removeInputError (input, parent, error) {
    if (input.getAttribute('type') === 'radio' && parent.parentNode.parentNode.getElementsByClassName('validation-error').length > 0) {
      parent.parentNode.parentNode.removeChild(parent.parentNode.parentNode.lastElementChild)
    } else {
      parent.classList.remove('has-validation-error', 'mb-8')
      input.classList.remove('error')
      error.remove()
    }
  }
}
