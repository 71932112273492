import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  toggle (e) {
    e.preventDefault()

    console.log('presence', this.linkTarget)
    this.linkTarget.querySelector('#offline').classList.toggle('hidden')
    this.linkTarget.querySelector('#online').classList.toggle('hidden')

    window.hubChannel.togglePresence()
  }
}
