import { Controller } from '@hotwired/stimulus'
import { EventBus } from 'lib/events'

export default class extends Controller {
  static targets = ['modal']

  show (e) {
    this.modalTarget.classList.remove('hidden')
    EventBus.emit('modal:show', this.modalTarget)
  }

  hide (e) {
    this.modalTarget.classList.add('hidden')
    EventBus.emit('modal:hide', this.modalTarget)
  }

  stopPropagation (e) {
    e.stopPropagation()
  }
}
