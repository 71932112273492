// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'css/application.css'

import 'channels'
import 'controllers'

import 'lib/events.js'

import { Turbo } from '@hotwired/turbo-rails'

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

// import menu from 'lib/menu'

require('@rails/ujs').start()
require('@rails/activestorage').start()
// menu()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('./images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line

// Make accessible for Electron and Mobile adapters
window.Turbo = Turbo

// Something with the upgrade to webpack caused odd scrolling issue on reload
if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual'
} else {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }
}
