import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['emailDiv', 'phoneDiv']

  connect () {
    this.emailDivTarget.classList.add('hidden')
    this.phoneDivTarget.classList.add('hidden')
  }

  toggleInvitationVia (e) {
    let target, oppositeTarget

    if (e.target.value === 'email') {
      target = this.emailDivTarget
      oppositeTarget = this.phoneDivTarget
    } else if (e.target.value === 'sms') {
      target = this.phoneDivTarget
      oppositeTarget = this.emailDivTarget
    }

    target.classList.remove('hidden')
    target.querySelector('input').required = true
    oppositeTarget.classList.add('hidden')
    oppositeTarget.querySelector('input').required = false
  }
}
