import consumer from './consumer'

if (document.querySelector("meta[name='current-user']")) {
  window.hubChannel = consumer.subscriptions.create('HubChannel', {
    connected () {
      // Called when the subscription is ready for use on the server
    },

    disconnected () {
      // Called when the subscription has been terminated by the server
    },

    received (data) {
      // Called when there's incoming data on the websocket for this channel
      const el = document.querySelector(`#${data.id}`)

      if (el !== null) {
        const parent = el.parentNode
        const node = new window.DOMParser().parseFromString(data.html, 'text/html').body.firstElementChild
        if (node instanceof window.Node) {
          parent.replaceChild(node, el)
        }
      }
    },

    read (id) {
      this.perform('read', { id: id })
    },

    togglePresence () {
      this.perform('toggle_presence', {})
    }
  })
}
