import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['children']

  connect () {
    const inputs = this.element.querySelectorAll('input[type="radio"]')
    inputs.forEach((i) => {
      if (i.checked && i.value === 'yes') {
        this.childrenTarget.classList.remove('hidden')
      }
    })
  }

  change (e) {
    if (e.target.value === 'yes') {
      this.childrenTarget.classList.remove('hidden')
    } else if (e.target.value === 'no') {
      this.childrenTarget.classList.add('hidden')
    }
  }
}
