import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['query', 'results', 'inputField']
  static values = {
    searchAction: String
  }

  connect () {
    this.timeout = null

    if (this.inputFieldTarget.value) {
      this.queryTarget.value = this.inputFieldTarget.value
    }
  }

  search (_e) {
    const doSearch = function () {
      const params = new URLSearchParams()
      params.append('query', this.queryTarget.value)
      params.append('target', this.resultsTarget.getAttribute('id'))
      get(`${this.searchActionValue}?${params}`, { responseKind: 'turbo-stream' })
      this.showList()
    }.bind(this)

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      doSearch()
    }, 300)
  }

  hideList () {
    setTimeout(() => {
      this.resultsTarget.classList.add('hidden')
    }, 200)
  }

  showList () {
    this.resultsTarget.classList.remove('hidden')
  }

  select (e) {
    // e.params will work w/ Stimuls 3+
    const value = e.target.dataset.searchSelectValueParam
    const label = e.target.dataset.searchSelectLabelParam
    this.updateChoosenValues(value, label)
    this.hideList()
  }

  updateChoosenValues (value, label) {
    this.inputFieldTarget.value = value
    this.queryTarget.value = label
  }
}
