import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu']

  initialize () {
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  connect () {
    this.element.addEventListener('mouseenter', this.show)
    this.element.addEventListener('mouseleave', this.hide)
  }

  disconnect () {
    this.element.removeEventListener('mouseenter', this.show)
    this.element.removeEventListener('mouseleave', this.hide)
  }

  show () {
    window.clearTimeout(this.hideTimeout)
    this.menuTarget.classList.remove('lg:hidden')
    window.setTimeout(() => {
      this.menuTarget.classList.remove('lg:opacity-0')
      this.menuTarget.classList.add('lg:opacity-100')
    }, 1)
  }

  hide () {
    this.menuTarget.classList.remove('lg:opacity-100')
    this.menuTarget.classList.add('lg:opacity-0')
    this.hideTimeout = window.setTimeout(() => {
      this.menuTarget.classList.add('lg:hidden')
    }, 500)
  }
}
